import { fetchPlanFamilies } from "../store/planFamilyState";
import { fetchWccPlans } from "../store/wccPlanState";
import store from "../store/store";
const state = store.getState();
const dispatch = store.dispatch;

const setPlanFamilies = () => {
  const plans = state?.planFamilies?.allPlanFamilies;
  if (!plans || !Object.keys(plans).length) {
    const dispatch = store.dispatch;
    fetchPlanFamilies()(dispatch, store.getState);
  }
};

const setWccPlans = () => {
  const plans = state?.planFamilies?.allWccPlans;
  if (!plans || !Object.keys(plans).length) {
    fetchWccPlans()(dispatch,store.getState);
  }
};

export default { setPlanFamilies, setWccPlans };
