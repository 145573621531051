import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  withStyles,
  Box,
  CircularProgress,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { connect } from "react-redux";
import { modifyUser, signOut } from "../Login/loginstate";
import { fetchPartnerDetails } from "../../store/partnerState";
import { Alert } from "@material-ui/lab";

import { URL as url } from "../../config/config";
class AgentProfilePage extends Component {
  state = {
    displayName: "",
    password: "",
    confirmPassword: "",
    changeName: false,
    changePassword: false,
    emailHelper: "",
    showPassword: false,
    status: "",
    isLoading: false,
    faviconUrl: "",
    brandLogoUrl: "",
    webhookUrl: "",
    loadingGifUrl: "",
  };
  timeout = null;

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setEmailMessage = () => {
    if (this.state.changeName) {
      this.setState({ emailHelper: "You cannot change your email address!" });
    }
  };

  changePassword = () => {
    if (this.state.changeName) {
      this.setState({ changePassword: true });
    }
  };

  onEdit = () => {
    this.setState({ changeName: true });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onCancel = () => {
    this.setState({
      changeName: false,
      changePassword: false,
      displayName: "",
      password: "",
      confirmPassword: "",
      emailHelper: "",
      oldPassword: "",
      newPassword: "",
      faviconUrl: "",
      brandLogoUrl: "",
      webhookUrl: "",
      loadingGifUrl: "",
      alertType: "",
      alertMsg: "",
    });
  };

  onSave = async () => {
    const {
      displayName,
      password,
      confirmPassword,
      faviconUrl,
      brandLogoUrl,
      webhookUrl,
      loadingGifUrl,
    } = this.state;
    this.setState({ isLoading: true });
    if (
      displayName ||
      faviconUrl ||
      brandLogoUrl ||
      webhookUrl ||
      loadingGifUrl
    ) {
      this.onProfileChange();
    }
    if (password || confirmPassword) {
      this.onPasswordChange();
    }
  };

  onPasswordChange = async () => {
    const { password, confirmPassword } = this.state;
    axios
      .post(url + "/auth/password-reset", {
        // displayName: displayName ? displayName : this.props.agent.displayName,
        oldPassword: password,
        newPassword: confirmPassword,
      })
      .then((response) => {
        const { token } = response.data;
        localStorage.setItem("jwtToken", token);
        this.props.modifyUser(token);

        this.setState(
          {
            alertType: "success",
            alertMsg: "password changed successfully",
            isLoading: false,
          },
          this.onCancel
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          isLoading: false,
        });
      });
  };

  onProfileChange = async () => {
    const { displayName, faviconUrl, brandLogoUrl, webhookUrl, loadingGifUrl } =
      this.state;
    const updateFields = {};
    if (displayName) updateFields.brandName = displayName;
    if (faviconUrl) updateFields.faviconUrl = faviconUrl;
    if (brandLogoUrl) updateFields.brandLogoUrl = brandLogoUrl;
    if (webhookUrl) updateFields.webhookUrl = webhookUrl;
    if (loadingGifUrl) updateFields.loadingGifUrl = loadingGifUrl;
    axios
      .patch(url + "/api/profile-update", updateFields)
      .then((response) => {
        this.props.fetchPartnerDetails();
        this.setState(
          {
            alertType: "success",
            alertMsg: "Successfully created",
            isLoading: false,
          },
          this.onCancel
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          isLoading: false,
          changeName: false,
        });
      });
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  render() {
    const {
      password,
      confirmPassword,
      displayName,
      showPassword,
      faviconUrl,
      brandLogoUrl,
      webhookUrl,
      loadingGifUrl,
    } = this.state;
    const { changeName, changePassword, emailHelper, isLoading } = this.state;
    const { success, statusMessage } = this.state;
    const { classes, agent } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <Box pb={{ xs: 2, md: 4 }} pl={{ xs: 1, md: 0 }}>
              <Typography variant="h2" gutterBottom>
                Profile
              </Typography>
              <Typography variant="body1" gutterBottom color="textSecondary">
                View and manage your personal details
              </Typography>
            </Box>
            <Box p={{ xs: 4, md: 3 }}>
              <Grid
                item
                xs={12}
                md={10}
                container
                direction="row-reverse"
                justifyContent="flex-start"
              >
                <Grid
                  item
                  md={6}
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <Box
                    pb={{ xs: 4, md: 2 }}
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar className={classes.avatar}></Avatar>
                  </Box>
                  {!changeName && (
                    <Button color="primary" onClick={this.onEdit}>
                      Edit
                    </Button>
                  )}
                  <Grid container justifyContent="center">
                    {!isLoading ? (
                      changeName ? (
                        <Grid container justifyContent="center">
                          <Box mt="30px">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.onCancel}
                            >
                              Cancel
                            </Button>
                          </Box>
                          <Box ml="30px" mt="30px">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.onSave}
                            >
                              Save
                            </Button>
                          </Box>
                        </Grid>
                      ) : (
                        <Button
                          variant="outlined"
                          color="inherit"
                          onClick={() => this.props.signOut(agent.id)}
                        >
                          Sign Out
                        </Button>
                      )
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box m="20px"></Box>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box m="20px"></Box>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box m="20px"></Box>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box m="20px"></Box>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box m="20px"></Box>
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <Box m="20px"></Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  {agent.name && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        Name
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="string"
                          value={agent.name}
                          placeholder={agent.name}
                          disabled={true}
                          name="name"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  )}
                  {agent.faviconUrl && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        Favicon Url
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="string"
                          value={changeName ? faviconUrl : agent.faviconUrl}
                          placeholder={agent.faviconUrl}
                          disabled={!changeName}
                          onChange={this.onChange}
                          name="faviconUrl"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  )}
                  {agent.brandLogoUrl && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        BrandLogo Url
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="string"
                          value={changeName ? brandLogoUrl : agent.brandLogoUrl}
                          placeholder={agent.brandLogoUrl}
                          disabled={!changeName}
                          name="brandLogoUrl"
                          onChange={this.onChange}
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  )}
                  {agent.webhookUrl && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        webhook Url
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="string"
                          value={changeName ? webhookUrl : agent.webhookUrl}
                          placeholder={agent.webhookUrl}
                          disabled={!changeName}
                          name="webhookUrl"
                          onChange={this.onChange}
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  )}
                  {agent.loadingGifUrl && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        loadingGif Url
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="string"
                          value={
                            changeName ? loadingGifUrl : agent.loadingGifUrl
                          }
                          placeholder={agent.loadingGifUrl}
                          disabled={!changeName}
                          name="loadingGifUrl"
                          onChange={this.onChange}
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid container direction="column" alignItems="flex-start">
                    <Typography variant="h4" align="center">
                      Current Password
                    </Typography>
                    <Box width="100%" boxSizing="border-box" mt="0px" mb="10px">
                      <TextField
                        variant="outlined"
                        autoComplete="off"
                        margin="dense"
                        name="password"
                        disabled={!changeName}
                        onChange={this.onChange}
                        onFocus={this.changePassword}
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={changePassword ? password : "***********"}
                        placeholder="***********"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                disabled={!changeName}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ autocomplete: "off" }}
                      />
                    </Box>
                  </Grid>
                  {changePassword && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        New password
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          name="confirmPassword"
                          disabled={!changePassword}
                          onChange={this.onChange}
                          fullWidth
                          placeholder="***********"
                          type={showPassword ? "text" : "password"}
                          value={confirmPassword}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid container direction="column" alignItems="flex-end">
                    {statusMessage && (
                      <Typography
                        style={{
                          width: "100%",
                          color: success ? "green" : "red",
                        }}
                        align="left"
                        variant="body1"
                      >
                        {statusMessage}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "whitesmoke",
    boxSizing: "border-box",
    overflowY: "auto",
    paddingTop: "60px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      paddingTop: "70px",
      paddingLeft: theme.spacing(1) * 1.5,
      paddingRight: theme.spacing(1) * 1.5,
    },
  },
  progress: {
    position: "fixed",
    top: "8em",
    right: "44.5vw",
  },
  avatar: {
    height: "2em",
    width: "2em",
    fontSize: "4em",
  },
  googleButtonContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    height: "50px",
    backgroundColor: "lightgrey",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: "15px",
    justifyContent: "center",
  },
});

const connectedAgentProfilePage = connect(
  (state) => ({
    agent: state.partner.partner,
  }),
  { modifyUser, signOut, fetchPartnerDetails }
)(AgentProfilePage);

export default withStyles(styles)(connectedAgentProfilePage);

//agent {agent.displayName.substring(0, 1).toUpperCase()}
