import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  withStyles,
  TextField,
  Grid,
  Typography,
  Button,
  Box,
  InputAdornment,
  Tooltip,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { createnewClient } from "./Clientstore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dialCodes, { countryMap, isoMap } from "../../config/dialCodes";
import { timeZones } from "../../config/timezone";

export const minBusinessFieldsPartners = process.env
  .MIN_BUSINESS_FIELDS_PARTNERS
  ? process.env.MIN_BUSINESS_FIELDS_PARTNERS.split(",")
  : ["646357594e6a060aa94cb99f"];

class AdDetailStep extends Component {
  handleInput = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    this.props.updateState(e.target.name, e.target.value);
  };

  render() {
    const { classes, partner } = this.props;
    const partnerId = partner._id;
    const getflag = (langcode) => {
      var first = langcode.charCodeAt(0) + 127397;
      var second = langcode.charCodeAt(1) + 127397;
      var flag = `&#${first};&#${second};`;
      const x = document.createElement("p");
      x.innerHTML = flag;
      return x.innerText;
    };
    const SIZE = [
      "1 - 10 Employees",
      "10 - 20 Employees",
      "20 - 50 Employees",
      "50 - 200 Employees",
      "200 + Employees",
    ];

    const VERTICALS = [
      // "Ecommerce",
      "Education",
      "Automotive",
      // "IT Services",
      // "Real Estate",
      // "SAAS/Apps",
      // "Gaming",
      "Entertainment",
      "Finance and Banking",
      "Medical and Health",
      "Hotel and Lodging",
      "Beauty, Spa and Salon",
      "Clothing and Apparel",
      "Event Planning and Service",
      "Food and Grocery",
      "Professional Services",
      "Public Service",
      "Non-profit",
      "Shopping and Retail",
      "Travel and Transportation",
      "Restaurant",
      "Other",
    ];

    return (
      <Grid xs container marginTop="3em" justify="center">
        <Box>
          <Typography
            variant="h3"
            style={{
              marginBottom: "16px",
            }}
            color="primary"
          >
            Create Your Business Here
          </Typography>

          <TextField
            id="name"
            label="Full Name"
            variant="outlined"
            type="text"
            name="display_name"
            onChange={this.handleInput}
            value={this.props.display_name}
            className={classes.textFieldRoot}
            required
          />
          <TextField
            id="email"
            label="Email Id"
            variant="outlined"
            type="text"
            name="email"
            onChange={this.handleInput}
            value={this.props.email}
            className={classes.textFieldRoot}
            required
          />

          {this.props.partnerType === "DIRECT" && (
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              onChange={this.handleInput}
              value={this.props.password}
              className={classes.textFieldRoot}
            />
          )}

          {!minBusinessFieldsPartners.includes(partnerId) && (
            <TextField
              id="company"
              label="Company Name"
              variant="outlined"
              type="text"
              name="company"
              onChange={this.handleInput}
              value={this.props.company}
              className={classes.textFieldRoot}
              required
            />
          )}

          <TextField
            id="website"
            label="Website"
            variant="outlined"
            type="text"
            name="website"
            onChange={this.handleInput}
            value={this.props.website}
            className={classes.textFieldRoot}
            required
          />

          <TextField
            id="description"
            label="Business description"
            variant="outlined"
            type="text"
            name="description"
            onChange={this.handleInput}
            value={this.props.description}
            className={classes.textFieldRoot}
          />

          {!minBusinessFieldsPartners.includes(partnerId) && (
            <Autocomplete
              disableClearable
              options={SIZE.map((option) => option)}
              onChange={(e, value, reason) => {
                const companySize = "companySize";
                this.props.updateState(companySize, value);
              }}
              value={this.props.companySize}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company Size"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                  required
                />
              )}
            />
          )}
          {!minBusinessFieldsPartners.includes(partnerId) && (
            <Autocomplete
              disableClearable
              options={VERTICALS.map((option) => option)}
              onChange={(e, value, reason) => {
                const industry = "industry";
                this.props.updateState(industry, value);
              }}
              value={this.props.industry}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Industry"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                  required
                />
              )}
            />
          )}

          <Box position="relative">
            <Autocomplete
              disableClearable
              options={dialCodes.map((i) => i.dialCode)}
              classes={{ popper: classes.textFieldPopper }}
              getOptionLabel={(option) => {
                return `${getflag(isoMap[option])} ${option}`;
              }}
              onChange={(e, value, reason) => {
                const countryCode = "countryCode";
                this.props.updateState(countryCode, value);
              }}
              value={this.props.countryCode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  classes={{ root: classes.codeTextFieldRoot }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />

            <TextField
              id="contact"
              placeholder="Mobile Number"
              type="text"
              name="contact"
              onChange={this.handleInput}
              value={this.props.contact}
              className={classes.phoneTextFieldRoot}
              required
            />
          </Box>

          {!minBusinessFieldsPartners.includes(partnerId) && (
            <Autocomplete
              disableClearable
              options={["INR", "USD"]}
              onChange={(e, value) => {
                const currency = "currency";
                this.props.updateState(currency, value);
              }}
              value={this.props?.partner?.currency}
              disabled="true"
              name="currency"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Preferred Billing Currency"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          )}
          <Autocomplete
            disableClearable
            options={timeZones.map((option) => option)}
            onChange={(e, value) => {
              const timezone = "timezone";
              this.props.updateState(timezone, value);
            }}
            value={this.props.timezone}
            name="timezone"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Timezone"
                classes={{ root: classes.textFieldRootAutocomplete }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Box>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: "20px 10px",
    display: "flex",
  },
  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
  },
  TextFieldRoot: {
    width: "100%",
    padding: "2px",
    marginTop: "4px",
    // marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)" },
    background: "rgb(240,240,240)",
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  passwordIcon: {
    cursor: "pointer",
    fontSize: 20,
  },
});

const Adsconnect = connect(
  (state) => ({
    partner: state.partner.partner,
  }),
  { createnewClient }
)(AdDetailStep);

export default withStyles(styles)(withRouter(Adsconnect));
