import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Radio,
  Snackbar,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { updateOnboardMethod } from "../../store/partnerState";
import { URL } from "../../config/config";
class OnboardingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      selectedValue: this.props.partner?.onboardingMethod,
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  handleChange = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
  };

  updateOnboardingMethod = () => {
    this.setState({
      planSubmitLoader: true,
    });

    const updateDefaultMethod = this.state.selectedValue;
    if (!updateDefaultMethod) {
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Select a method",
      });
      return;
    }
    axios
      .patch(URL + `/api/update-partner-config`, {
        onboardingMethod: updateDefaultMethod,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Successfully changed onboarding method",
          planSubmitLoader: false,
        });
        this.props.updateOnboardMethod({
          onboardingMethod: updateDefaultMethod,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "Onboarding method update failed",
          planSubmitLoader: false,
        });
      });
  };

  render() {
    const { classes, match } = this.props;
    const assistantId = match.params.projectId;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Header businessTitle={"Configure Onboarding"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">Select Onboarding Method</Typography>
                  <Box mt={5} />
                  <Box>
                    <Grid container>
                      <Grid item xs={12} md={1} className={classes.cells}>
                        <Radio
                          checked={
                            this.state.selectedValue === "WHATSAPP_API_FORM"
                          }
                          onChange={this.handleChange}
                          value="WHATSAPP_API_FORM"
                          name="radio-button-demo"
                          color="primary"
                          inputProps={{ "aria-label": "WHATSAPP_API_FORM" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cells}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        WhatsApp API Form
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.cells}>
                        <Radio
                          checked={
                            this.state.selectedValue === "EMBEDDED_SIGNUP"
                          }
                          onChange={this.handleChange}
                          value="EMBEDDED_SIGNUP"
                          name="radio-button-demo"
                          color="primary"
                          inputProps={{ "aria-label": "EMBEDDED_SIGNUP" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cells}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        Embedded Signup
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.cells}>
                        <Radio
                          checked={this.state.selectedValue === "NONE"}
                          onChange={this.handleChange}
                          value="NONE"
                          name="radio-button-demo"
                          color="primary"
                          inputProps={{ "aria-label": "NONE" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cells}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        None
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 24,
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.updateOnboardingMethod}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
});

const connectOnboardingPage = connect(
  (state) => ({
    partner: state.partner.partner,
  }),
  {
    updateOnboardMethod,
  }
)(OnboardingPage);
export default withStyles(styles)(connectOnboardingPage);
